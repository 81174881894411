import React from "react";
import Wrapper from "./components/Wrapper";

function AdminPanel() {
  return <Wrapper>
    <div>hello</div>
  </Wrapper>;
}

export default AdminPanel;
