import React, { useEffect } from 'react';

const LiveChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src="//code.tidio.co/xew36qyknnu0pvgmbcamyismouaxwi4s.js";    
    script.async = true;
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  return null; // You can add a placeholder or styling here if needed
};


export default LiveChat;
