import React from "react";
import "./contact.css";
import { useState } from "react";

const Contacts = () => {
  const [user, setUser] = useState(
    {
      Name:'', Email:'',Subject:'',Message:''
    }
  )
  let name, value 
  const data = (e) =>{
    name = e.target.name;
    value = e.target.value;
    setUser({...user, [name]: value})
  }
  return (
    <>
      <div className="contactContainer1">
        <div className="content">
          <h2> Contact us </h2>
          <div className="form">
            <form method="POST">
              <input
                type="text"
                name="Name"
                value={user.Name}
                placeholder="Enter Your Full Name"
                required
                autoComplete="off"
                onChange={data}
              />
              <input
                type="text"
                name="Email"
                value={user.Email}
                placeholder="Enter Your E-mail"
                required
                autoComplete="off"
                onChange={data}
              />
              <input
                type="text"
                name="Subject"
                value={user.Subject}
                placeholder="Enter Your Subject"
                required
                autoComplete="off"
                onChange={data}
              />
              <textarea
                name="Message"
                value={user.Message}
                placeholder="Your Message"
                required
                autoComplete="off"
                onChange={data}
              />
              <button type="submit"> Send </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
